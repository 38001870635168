<template>
  <div class="q-pa-lg flex q-mt-lg flex-center">
    <q-pagination
      color="black"
      :max="max"
      v-model="currentPage"
      :max-pages="6"
      direction-links
      boundary-numbers
    />
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "PaginationComponent",

  props: {
    current: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },

  emits: ["changeCurrent"],

  setup(props, { emit }) {
    const currentPage = ref(props.current);

    watch(currentPage, (cv) => emit("changeCurrent", cv));

    return {
      currentPage,
    };
  },
};
</script>

<style scoped lang="scss">
.link {
  text-decoration: none;
}
</style>
