<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('missions.show_rewards')"
          />
          <q-space />
          <q-btn icon="close" color="red" flat round dense v-close-popup />
        </q-card-section>

        <q-separator class="q-mr-md q-ml-md" />

        <q-card-section class="q-pt-none q-mt-md bg-grey-2 q-ma-sm q-pa-md">
          <div class="row">
            <div
              class="col-12 col-md-4"
              v-for="(reward, i) in rewards"
              :key="i"
            >
              <reward-card-component :reward="reward" />
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            color="black"
            class="q-ml-sm"
            v-close-popup
            :label="$t('global.close')"
          />
        </q-card-actions>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import RewardCardComponent from "./RewardCardComponent.vue";

export default {
  name: "RewardsComponent",

  components: {
    RewardCardComponent,
  },

  props: {
    rewards: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 900px;
  max-width: 80vw;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
