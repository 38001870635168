<template>
  <div :class="{ 'q-ml-lg q-mr-md': $q.platform.is.desktop }">
    <q-item-label
      lines="1"
      class="text-weight-bold text-uppercase q-mb-md text-justify q-mt-md card-title"
      >{{ mission.title }}</q-item-label
    >
    <div class="row q-mb-md card-data">
      <div class="col-12 col-md-12">
        <q-icon name="monetization_on" class="label-color" />
        <span
          class="label-color"
          v-html="getLabel(mission.rewards.length, mission.credits_if_complete)"
        />
      </div>
      <div class="col-12 col-md-6 q-mt-md" v-if="verifyPeriod(mission)">
        <span>
          <q-icon name="event" />
          {{ getEventPeriod(mission) }}
        </span>
      </div>
      <div class="col-12 col-md-6 q-mt-md" v-if="mission.requirements.length">
        <span>
          <q-icon name="hourglass_empty" />
          {{
            $t(
              mission.requirements.length
                ? "missions.has_requirements"
                : "missions.hasnt_requirements"
            )
          }}
        </span>
      </div>
      <div class="col-12 q-mt-md" v-if="mission.level">
        <span>
          <q-icon name="insights" />
          {{ $t(mission.level.level) }}
        </span>
      </div>

      <div class="col-12 col-md-6 q-mt-md" v-if="mission.rewards.length">
        <a
          href="#"
          class="text-primary text-weight-bolder"
          @click.prevent="rewardModal = true"
          style="text-decoration: none;"
        >
          <span
            class="card-data"
            :class="$route.name == 'missions' ? '' : 'link-public'"
            ><q-icon name="redeem" /> {{ $t("missions.show_rewards") }}</span
          ></a
        >
      </div>
    </div>
  </div>

  <rewards-component
    v-model="rewardModal"
    :rewards="mission.rewards"
    v-if="mission.rewards.length"
  />
</template>

<script>
import RewardsComponent from "./internal-components/RewardsComponent.vue";
import { getLabel, getEventPeriod } from "../helpers/script_helpers";
import { ref } from "vue";

export default {
  name: "BasicInfoComponent",

  components: {
    RewardsComponent,
  },

  props: {
    mission: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const rewardModal = ref(false),
      verifyPeriod = (mission) => mission.time_in_days || mission.start_at;

    return {
      rewardModal,
      getLabel,
      verifyPeriod,
      getEventPeriod,
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/styles/app.scss";
.card-title {
  font-size: $cardTitle;
}
.card-data {
  font-size: $cardData;
}

.link-public {
  color: $greenColorSite;
}

.label-color {
  color: $pinkColorSite;
}

@media screen and (max-width: 1600px) {
  .card-title {
    font-size: $cardTitle1600;
  }
  .card-targets {
    width: 25px;
    height: 25px;
  }
  .card-data {
    font-size: $cardData1600;
  }
}

@media screen and (max-width: 1368px) {
  .card-title {
    font-size: $cardTitle1368;
  }
  .card-data {
    font-size: $cardData1368;
  }
}

.mobile {
  .card-title {
    font-size: $cardTitleMob;
  }
  .card-data {
    font-size: $cardDataMob;
  }
}
</style>
